$primaryColor: var(--primary-color);
$primaryDarkColor: var(--primary-dark-color);
$primaryFontColor: var(--primary-font-color);
$dividerColor: var(--divider-color);
$secondaryColor: var(--secondary-color);
$blueishGrey: var(--blueish-grey);
$backgroundColor: var(--background-color);
$highlightBackground: var(--highlight-background);
$light: var(--light);
$dark: var(--dark);
$dangerColor: var(--danger-color)